import styled from 'styled-components';

const Header = styled.h2`
    font-size: ${({theme, big}) => (big ? theme.font_xxl : theme.font_xl)};
    margin-bottom: 25px;
    text-transform: uppercase;
    font-weight: 900;
    font-weight: ${({big}) => (big ? 900 : 700)};
    @media (${({theme}) => theme.media_phone}) {
        font-size: ${({big}) => (big ? '35px' : '25px')};
    }
`;
export default Header;