import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { routes } from '../routes';
import HomeTemplate from '../templates/HomeTemplate';
import HomePage from './HomePage';
import ContactPage from './ContactPage';
import ServicesPage from './ServicesPage';
import RealizationPage from './RealizationPage';
import ScrollToTop from '../function/ScrollToTop';

const Root = () => {

  return (
      <BrowserRouter>
          <HomeTemplate>
          <ScrollToTop/>
            <Switch>
              <Route exact path={routes.home} component={HomePage}/>
              <Route path={routes.contact} component={ContactPage}/>
              <Route path={routes.services} component={ServicesPage}/>
              <Route path={routes.realization} component={RealizationPage}/>
              <Route path="*" component={HomePage}/>
            </Switch>
          </HomeTemplate>
      </BrowserRouter>
  );
}

export default Root;
