import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import Header from '../atoms/Header';
import Button from '../atoms/Button';
import markIcon from '../../assets/mark.svg';
import { routes } from '../../routes';
import Paragraph from '../atoms/Paragraph';
import arrowIcon from '../../assets/arrow-white.svg';
import arrowIconBase from '../../assets/arrow-base.svg';



const Wrapper = styled.div`
    width: 100%;
    padding: 50px 0;
    position: relative;
    text-align: center;
`;
const InnerWrapper = styled.div`
    margin: 8vw 0 4vw;
    display: grid;
    grid-template-columns: repeat(3,1fr);
    grid-gap: 50px;
    @media (${({theme}) => theme.media_phone}) {
        grid-template-columns: 1fr;
    }
`;
const Card = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    @media (${({theme}) => theme.media_phone}) {
        &:last-of-type {
            margin-bottom: 50px;
        }
    }
`;
const CardImg = styled.div`
    width: 100%;
    overflow: hidden;
    border-radius: 5px;
`;
const Img = styled.img`
    width: 100%;
    transition-duration: 0.5s;
    border-radius: 5px;
    filter: grayscale(50%);
    &:hover {
        transform: scale(1.7);
        filter: grayscale(0%);
    }
`;

const MainHeader = styled(Header)`
    position: relative;
    &:after {
        content: '';
        position: absolute;
        width: 3.6vw;
        height: 3.6vw;
        background: url(${markIcon});
        background-repeat: no-repeat;
        background-size: 100% 100%;
        opacity: 0.4;
        bottom: -3.7vw;
    }
`;
const StyledLink = styled.a`
    margin-top: 10px;
    color: white;
    position: relative;
    transition-duration: 0.4s;
    text-decoration: none;
    padding: 10px 60px 10px 0;
    text-transform: uppercase;
    &:after {
        content: '';
        width: 25px;
        height: 25px;
        background: url(${arrowIcon});
        background-size: 80%;
        background-position: 50%;
        background-repeat: no-repeat;
        position: absolute;
        right: 0;
        top: 8px;
        transition-duration: 0.4s;
        border-radius: 50px;
    }
    &:hover {
        transform: translateX(5px);
        color: ${({theme}) => theme.secondary_2};
        &:after {
            transform: translateX(10px);
            background-image: url(${arrowIconBase});
        }
    }
`;
const StyledParagraph = styled(Paragraph)`
    margin: 0 0 10px;
    font-weight: bold;
    color: ${({theme}) => theme.primary};
    font-size: ${({theme}) => theme.font_l};
    @media (${({theme}) => theme.media_phone}) {
        font-size: 18px;
    }
`;
const SmallParagraph = styled(Paragraph)`
    font-size: ${({theme}) => theme.font_s};
    color: grey;
    @media (${({theme}) => theme.media_phone}) {
        font-size: 12px;
    }
`;

const Realization = ({items, header}) => (
    <Wrapper>
        {header && <MainHeader>Ostatnie realizacje</MainHeader>}
        <InnerWrapper>
            {
                items.map(({name, description, cardIcon, link}) => (
                    <Card>
                    <CardImg>
                        <Img src={cardIcon}/>
                    </CardImg>
                    <SmallParagraph>Projekt i realizacja firmowej strony internetowej</SmallParagraph>
                    <StyledParagraph>{name}</StyledParagraph>
                    <Paragraph>{description}</Paragraph>
                    <StyledLink href={link} target="_blank">przejdź do strony</StyledLink>
                </Card>
                ))
            }
        </InnerWrapper>
        {header && <Button as={NavLink} to={routes.realization}>Zobacz wszystkie usługi</Button>}
    </Wrapper>
);

export default Realization;