import { createGlobalStyle } from 'styled-components';
import Montserrat400 from '../fonts/Montserrat/Montserrat-Regular.ttf';
import Montserrat700 from '../fonts/Montserrat/Montserrat-Bold.ttf';
import Montserrat900 from '../fonts/Montserrat/Montserrat-Black.ttf';

const GlobalStyle = createGlobalStyle`
    @font-face {
        font-family: Montserrat-my;
        src: local('Montserrat-my'),
        url(${Montserrat400});
        font-weight: 400;
    }
    @font-face {
        font-family: Montserrat-my;
        src: local('Montserrat-my'),
        url(${Montserrat900});
        font-weight: 900;
    }
    @font-face {
        font-family: Montserrat-my;
        src: local('Montserrat-my'),
        url(${Montserrat700});
        font-weight: 700;
    }
    @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700;900&display=swap');
    *, *::after, *::before {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
    }
    body {
        font-family: 'Montserrat-my', 'Montserrat';
        background: black;
        color: white;
        scroll-behavior: smooth;
    }
    html {
        scroll-behavior: smooth;
    }
`;

export default GlobalStyle;