import React from 'react';
import styled from 'styled-components';
import Header from '../atoms/Header';
import Paragraph from '../atoms/Paragraph';
import markIcon from '../../assets/mark.svg'
import Span from '../atoms/Span';
import fbIcons from '../../assets/facebook.svg';
import igIcons from '../../assets/instagram.svg';
import bacIcon from '../../assets/bac.svg';
import SocialIcon from '../atoms/SocialIcon';
import { links } from '../../links';

const Wrapper = styled.div`
    width: 100%;
    min-height: 85vh;
    margin: 0 auto;
    display: flex;
    align-items: center;
    position: relative;
    background: url(${bacIcon});
    @media (${({theme}) => theme.media_phone}) {
        min-height: calc(100vh - 80px);
    }
`;

const ContentWrapper = styled.div`
    width: 85%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    @media (${({theme}) => theme.media_phone}) {
        width: 100%;
        align-items: center;
        text-align: center;
    }
`;

const StyledParagraph = styled(Paragraph)`
    margin: 20px 0 50px;
    font-size: ${({theme}) => theme.font_l};
    line-height: 2.5rem;
    @media (${({theme}) => theme.media_phone}) {
        text-align: center;
    }
`;
const Image = styled.div`
    position: absolute;
    width: 10vw;
    height: 12vw;
    content: '';
    background: url(${markIcon});
    background-size: 100%;
    background-repeat: no-repeat;
    opacity: 0.5;
    bottom: 30%;
    right: 0;
    @media (${({theme}) => theme.media_phone}) {
        position: absolute;
        bottom: 0;
        right: 20px;
        width: 80px;
        height: 100px;
    }
`;
const SocialMedia = styled.div`
    position: absolute;
    right: 0;
    bottom: 0;
    @media (${({theme}) => theme.media_phone}) {
        display: none;
    }
`;
const ScrollButton = styled.a`
     position: relative;
        text-decoration: none;
        border: 1px;
        border-style: solid;
        border-color: ${({theme}) => theme.secondary_2};
        padding: 15px 40px;
        border-radius: 50px;
        background: ${({theme}) => theme.secondary_2};
        color: white;
        transition: all .2s ease;
        font-size: ${({theme}) => theme.secondary_2};
        text-transform: uppercase;
        z-index: 9;
        &:hover {
            transform: translateY(-2px);
            background: none;
            color: ${({theme}) => theme.secondary_2};
            transform: translateY(-3px);
        }
    @media (${({theme}) => theme.media_phone}) {
        font-size: 16px;
    }
`;



const Slider = () => {

    return (
    <Wrapper>
        <ContentWrapper>
            <Header big>Oferujemy profesjonalne rozwiązania dla <Span>Twojej</Span> firmy<Span primary>.</Span></Header>
            <StyledParagraph>Specjalizujemy się w tworzeniu oraz wdrażaniu nowoczesnych stron internetowych dla firm i osób prywatnych. Oferujemy kompleksową pomoc z zakresu technologicznego na każdym etapie współpracy.</StyledParagraph>
            <ScrollButton href="#o-nas">dowiedz się więcej!</ScrollButton>
        </ContentWrapper>
        <Image/>
        <SocialMedia>
            <SocialIcon href={links.facebook} icon={fbIcons}/>
        <SocialIcon icon={igIcons} href={links.instagram}/>
        </SocialMedia>
    </Wrapper>
    )
};

export default Slider;
