import React from 'react';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import Button from '../atoms/Button';
import Paragraph from '../atoms/Paragraph';

const FormWrapper = styled.form`
    display: flex;
    flex-direction: column;
    width: 50%;
    padding-right: 10%;
    align-items: flex-start;
    @media (${({theme}) => theme.media_phone}) {
        width: 100%;
        padding: 0;
    }
`;
const Label = styled.label`
    text-align: center;
    font-size: ${({theme}) => theme.font_m};
    @media (${({theme}) => theme.media_phone}) {
        font-size: 16px;
    }
`;
const Input = styled.input`
    width: 100%;
    background: none;
    border: none;
    border-bottom: 1px solid rgba(255,255,255,0.4);
    color: white;
    position: relative;
    height: 30px;
    &:focus {
        border: 1px solid white;
    }
`;
const Textarea = styled.textarea`
    color: white;
    width: 100%;
    border: none;
    background: none;
    border-bottom: 1px solid rgba(255,255,255,0.4);
    resize: none;
`;
const InputWrapper = styled.div`
    position: relative;
    width: 100%;
    margin-bottom: 80px;
`;
const Error = styled(Paragraph)`
    color: red;
    position: absolute;
`;

const Form = () => {

    const { register, handleSubmit, errors } = useForm();
    const onSubmit = data => {
        console.log(data);
    };

    return (
    <FormWrapper onSubmit={handleSubmit(onSubmit)}>
        <InputWrapper>
            <Label>E-mail:</Label>
            <Input
                name="email"
                ref={register({
                required: true,
                pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "Sprawdź swój adres e-mail"
                    }
                })}
            />
            <Error>{errors.email && errors.email.message}</Error>
        </InputWrapper>
        <InputWrapper>
            <Label>Telefon: </Label>
            <Input
                name="number"
                type="tel"
                ref={register({
                    required: true,
                    minLength: 6,
                    maxLength: 12,
                    pattern: {
                        value: /[0-9]/,
                    }
                })}
            />
            <Error>{errors.number && "Wpisz poprawny numer telefonu"}</Error>
        </InputWrapper>
        <InputWrapper>
            <Label>Wiadomość: </Label>
            <Textarea name="message" ref={register}/>
        </InputWrapper>
        <Button type="submit">Wyślij</Button>
    </FormWrapper>
    )
};

export default Form;