import styled from 'styled-components';

const Paragraph = styled.p`
    line-height: ${({theme}) => theme.font_l};
    text-align: ${({center}) => (center ? 'center' : 'justify')};
    font-size: ${({theme}) => theme.font_m};
    @media (${({theme}) => theme.media_phone}) {
        text-align: ${({mediacenter}) => (mediacenter ? 'center' : 'justify')};
        font-size: 16px;
        line-height: 20px;
    }
`;

export default Paragraph;