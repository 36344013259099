import React from 'react';
import MessengerCustomerChat from 'react-messenger-customer-chat';


const Messenger = () => (
<MessengerCustomerChat
    pageId="101051615066006"
    appId="facebook-developer-app-id"
  />
);

export default Messenger;