import React from 'react';
import MainHeader from '../components/organisms/MainHeader';
import Populary from '../components/organisms/Populary';
import mediaIcon from '../assets/social-media.svg';
import pageIcon from '../assets/profile.svg';
import supportIcon from '../assets/settings.svg';
import blogIcon from '../assets/blog.svg';
import settingIcon from '../assets/setting.svg';
import adsIcon from '../assets/ads.svg';

const headerValue = {
    heading: 'Sprawdź w jaki sposób możemy Ci pomóc',
    description: 'Pomożemy Ci stworzyć własną markę w Internecie, zbudujemy wizerunek Twojej firmy. Zapraszamy do zapoznania się z naszą ofertą i kontaktu.'
}
const offers = [
    {
        name: 'social media',
        description: 'Zajmujemy się tworzeniem i prowadzeniem wizerunków firmowych w social-mediach. Tworzymy strategie i kampanie reklamowe.',
        cardIcon: mediaIcon,
        active: 0
    },
    {
        name: 'strony internetowe',
        description: 'Tworzymy profesjonalne i przejrzyste strony internetowe. Każdy projekt jest indywidualny i dostosowany do wymagać klienta.',
        cardIcon: pageIcon,
        active: 1
    },
    {
        name: 'obsługa techniczna',
        description: 'Dbamy o witryny internetowe od strony technicznej, wykonujemy prace związane z aktualizacją  i utrzymaniem stron internetowych.',
        cardIcon: supportIcon,
        acrive: 0
    },
    {
        name: 'blogi',
        description: 'Tworzymy blogi i pomagamy w ich promowaniu i prowadzeniu. Zajmujemy się tworzeniem profesjonalnych wpisów tematycznych.',
        cardIcon: blogIcon,
        active: 0
    },
    {
        name: 'google ads',
        description: 'Przygotowujemy skuteczne kampanie reklamowe przy pomocy Google Ads, analizujemy wyniki w celu lepszej skuteczności.',
        cardIcon: adsIcon,
        active: 1
    },
    {
        name: 'inne',
        description: 'Pomagamy w innych kwestiach związanych z technologią. Tworzymy szablony na allegro, konfigurujemy pocztę itp.',
        cardIcon: settingIcon,
        acrive: 0
    },
];
const ContactPage = () => (
    <>
    <MainHeader information={headerValue}/>
    <Populary items={offers}/>
    </>
);

export default ContactPage;