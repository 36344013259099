import React from 'react';
import styled from 'styled-components';
import Paragraph from '../atoms/Paragraph';
import Header from '../atoms/Header';
import Button from '../atoms/Button';
import { NavLink } from 'react-router-dom';
import certyficationIcon from '../../assets/certyfication.svg';
import contactIcon from '../../assets/contact.svg';
import manIcon from '../../assets/man.svg'
import markIcon from '../../assets/white_mark.svg';
import { routes } from '../../routes';

const Wrapper = styled.div`
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    position: relative;
    &:before {
        content: 'nasza oferta';
        position: absolute;
        top: 0;
        left: -90px;
        text-transform: uppercase;
        font-size: 11vw;
        font-weight: 900;
        color: rgba(255,255,255,0.05);
    }
    @media (${({theme}) => theme.media_phone}) {
        justify-content: center;
    }
`;
const Description = styled.div`
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    @media (${({theme}) => theme.media_phone}) {
        width: 100%;
    }
`;
const InnerWrapper = styled.div`
    width: 40%;
    display: flex;
    flex-direction: column;
    @media (${({theme}) => theme.media_phone}) {
        width: 100%;
    }
`;
const StyledHeader = styled(Header)`
    font-size: ${({theme}) => theme.font_l};
    font-weight: 700;
    position: relative;
    margin: 10px 0 10px;
    color: white;
    font-weight: 300;
    @media (${({theme}) => theme.media_phone}) {
        font-size: 18px;
    }
`;
const Offer = styled.div`
    padding: 50px;
    margin-bottom: 30px;
    transition-duration: 0.5s;
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px solid rgba(255,255,255,0.1);
    z-index: 99;
    @media (${({theme}) => theme.media_phone}) {
        padding: 15px;
    }
`;
const OfferWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    padding-left: 20px;
`;
const Icon = styled.img`
    width: 2vw;
    margin: 0 20px 0 0;
    padding: 0;
    @media (${({theme}) => theme.media_phone}) {
        width: 10%;
        margin-right: 10px;
    }
`;
const DescriptionButton = styled(Button)`
    margin-top: 50px;
    @media (${({theme}) => theme.media_phone}) {
        margin: 50px auto;
    }
`;
const Mark = styled.img`
    position: absolute;
    top: 40%;
    width: 400px;
    left: 30%;
    opacity: 0.08;
    display: none;
`;

const features = [
    {
        name: 'Profesionalna obsługa',
        description: 'Oferujemy pomoc z zakresu technologicznego na każdym etapie współpracy.',
        icon: manIcon,
    },
    {
        name: 'Szybki kontakt',
        description: 'Bardzo sprawnie odpowiadamy na wszystkie pytania.',
        icon: contactIcon,
    },
    {
        name: 'Pełne zadowolenie',
        description: 'Swoje zlecenia wykonujemy do momentu całkowitej akceptacji ze strony klienta.',
        icon: certyficationIcon,
    },
]

const AboutUs = () => (
    <Wrapper id="o-nas">
        <Description>
            <Header>Czym się zajmujemy</Header>
            <Paragraph>W luksite zajmujemy się profesjonalnym wsparciem firm i osób prywatnych z zakresu projektów internetowych. Głównie zajmujemy się projektowaniem  i wdrażaniem nowoczesnych witryn internetowych. Dobrze znamy swoich klientów i ściśle z nimi współpracujemy. </Paragraph>
            <Paragraph>Dzięki teoretycznej wiedzy i praktycznemu doświadczeniu nasze projekty są dostosowane do potrzeb i wymagań każdego klienta. Staramy się sprostać każdemu zleceniu i sumiennie wykonujemy powierzone nam zadania.</Paragraph>
            <DescriptionButton as={NavLink} to={routes.services}>sprawdź naszą ofertę</DescriptionButton>
        </Description>
        <Mark src={markIcon}/>
        <InnerWrapper>
            {
                features.map(({name, description, icon}) => (
                    <Offer>
                        <Icon src={icon}></Icon>
                        <OfferWrapper>
                            <StyledHeader>{name}</StyledHeader>
                            <Paragraph>{description}</Paragraph>
                        </OfferWrapper>
                    </Offer>
                ))
            }
        </InnerWrapper>
    </Wrapper>
);

export default AboutUs;