import styled, {css} from 'styled-components';

const Menu = styled.div`
        position: fixed;
        width: 50px;
        height: 30px;
        border-top: 3px solid white;
        top: 50px;
        right: 50px;
        z-index: 9999;
        transition-duration: 0.4s;
        &:before {
            content: '';
            position: absolute;
            top: calc(50% - 3px);
            left: 0;
            height: 3px;
            width: 70%;
            background: white;
            transition-duration: 0.4s;
        }
        &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            height: 3px;
            width: 40%;
            background: white;
            transition-duration: 0.5s;
        }
        &:hover {
            border-color: ${({theme}) => theme.primary};
            &:before {
                width: 100%;
                background: ${({theme}) => theme.primary};
            }
            &:after {
                width: 100%;
                background: ${({theme}) => theme.primary};
            }
        }
        ${({close}) =>
            close && css`
                border: none;
                &:before {
                    top: 50%;
                    width: 100%;
                    transform: rotate(45deg);
                }
                &:after {
                    top: 50%;
                    transform: rotate(-45deg);
                    width: 100%;
                }
                &:hover {
                    transform: rotate(180deg);
                }
            `
        }
    @media (${({theme}) => theme.media_phone}) {
        width: 40px;
        right: 10px;
        top: 20px;
    }
`;

export default Menu;