import React from 'react';
import styled from 'styled-components';
import Header from '../atoms/Header';
import Paragraph from '../atoms/Paragraph';
import Span from '../atoms/Span';
import bacIcon from '../../assets/bac.svg';


const MainWrapper = styled.div`
    padding: 10vh 0;
    background: url(${bacIcon});
    /* background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1600 900'%3E%3Cdefs%3E%3ClinearGradient id='a' x1='0' x2='0' y1='1' y2='0' gradientTransform='rotate(159,0.5,0.5)'%3E%3Cstop offset='0' stop-color='%234e7cb9'/%3E%3Cstop offset='1' stop-color='%23242e4c'/%3E%3C/linearGradient%3E%3ClinearGradient id='b' x1='0' x2='0' y1='0' y2='1' gradientTransform='rotate(89,0.5,0.5)'%3E%3Cstop offset='0' stop-color='%23893168'/%3E%3Cstop offset='1' stop-color='%23c31751'/%3E%3C/linearGradient%3E%3C/defs%3E%3Cg fill='%23FFF' fill-opacity='0' stroke-miterlimit='10'%3E%3Cg stroke='url(%23a)' stroke-width='3.3'%3E%3Cpath transform='translate(-70 -6.4) rotate(-10 1409 581) scale(0.90112)' d='M1409 581 1450.35 511 1490 581z'/%3E%3Ccircle stroke-width='1.1' transform='translate(-124 64) rotate(-0.7999999999999998 800 450) scale(0.99176)' cx='500' cy='100' r='40'/%3E%3Cpath transform='translate(-6.399999999999999 -12) rotate(-28 401 736) scale(0.99176)' d='M400.86 735.5h-83.73c0-23.12 18.74-41.87 41.87-41.87S400.86 712.38 400.86 735.5z'/%3E%3C/g%3E%3Cg stroke='url(%23b)' stroke-width='1'%3E%3Cpath transform='translate(384 14.4) rotate(-3.6 150 345) scale(1.01504)' d='M149.8 345.2 118.4 389.8 149.8 434.4 181.2 389.8z'/%3E%3Crect stroke-width='2.2' transform='translate(-36 -180) rotate(-57.599999999999994 1089 759)' x='1039' y='709' width='100' height='100'/%3E%3Cpath transform='translate(-160 32) rotate(-9.6 1400 132) scale(0.8)' d='M1426.8 132.4 1405.7 168.8 1363.7 168.8 1342.7 132.4 1363.7 96 1405.7 96z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    background-attachment: fixed;
    background-size: cover; */
`;
const Wrapper = styled.div`
    margin: 0 auto;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @media (${({theme}) => theme.media_phone}) {
        width: 100%;
        &:before {
            left: 5px;
        }
        &:after {
            left: 0;
            width: 10px;
        }

    }

`;
const Step = styled.div`
    margin: 10px 0;
    width: 48%;
    padding: 5vw;
    transform: translateY(0);
    &:nth-child(even) {
        transform: translateY(50%);
        position: relative;
    }
    @media (${({theme}) => theme.media_phone}) {
        width: 100%;
        &:nth-child(even) {
        transform: translateY(0);
        width: 100%;    }
    }

`;

const StyledHeader = styled(Header)`
    font-size: ${({theme}) => theme.font_l};
    text-transform: none;
    padding: 10px 0;
    margin: 0;
`;
const StyledSpan = styled(Span)`
    font-size: 8vw;
    font-weight: 700;
    position: absolute;
    z-index: -1;
    top: 1vw;
    left: 1vw;
    opacity: 0.5;
    @media (${({theme}) => theme.media_phone}) {
        font-size: 12vw;
        font-weight: 900;
    }
`;
const MainHeader = styled(Header)`
    text-align: center;
`;
const steps = [
    {
        index: '01',
        name: 'Analiza potrzeb',
        description: 'Na początku omawiamy i analizujemy wszystkie potrzeby Twojej firmy. Aby współpraca przebiegła prawidłowo, musimy poznać Twoje oczekiwania oraz zapoznać się z wyzwaniem, które dla nas przygotowałeś.'
    },
    {
        index: '02',
        name: 'Wykonywanie projektu',
        description: 'Po dokładnym poznaniu i analizie potrzeb przechodzimy do wykonywania projektu zgodnie z wcześniej ustalonym standardem. Wykonujemy wszystkie powierzone nam zadania, pozostając w stałej konsultacji.'
    },
    {
        index: '03',
        name: 'Udostępnienie efektu prac',
        description: 'Po wykonaniu wszystkich założeń, udostępniamy projekt wraz z dokładnym opisem wykonanych prac. Następnie oczekujemy na informację zwrotną. W tym czasie prosimy o dokładne sprawdzenie całego projektu oraz zapisanie ewentualnych uwag. W przypadku całkowitej akceptacji  - po ewentualnych poprawkach - przechodzimy do kolejnego kroku.  '
    },
    {
        index: '04',
        name: 'Wdrożenie',
        description: 'Po całkowitej akceptacji projektu, następuje jego wdrożenie. Na tym etapie udostępniamy projekt w sieci oraz wykonujemy wszelkie prace konfiguracyjne na serwerze. Dostarczamy wszystkie dane dostępu i materiały szkoleniowe z zakresu obsługi witryny.'
    }
];

const Steps = () => (
    <MainWrapper>
    <MainHeader>Jak wygląda współpraca z nami</MainHeader>
<Wrapper>
    {
        steps.map(({name, description, index}) => (
            <Step>
                <StyledSpan primary>{index}.</StyledSpan>
                <StyledHeader>{name}</StyledHeader>
                <Paragraph>{description}</Paragraph>
            </Step>
        ))
    }
</Wrapper>
</MainWrapper>
);

export default Steps;
