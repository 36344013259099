export const theme = {
    primary: '#4e7cb9',
    primary_1: '#242e4c',
    primary_2: '#1c2540',
    primary_3: '#1d58a5',
    primary_4: '#13396a',
    secondary_1: '#893168',
    secondary_2: '#c31751',
    grey_1: '#e6e7e8',
    shadow_1: 'inset 0 1px 0 rgba(255,255,255,.15), 0 1px 1px rgba(23,31,56,.075)',
    font_s: '0.8vw',
    font_m: '1vw',
    font_l: '1.6vw',
    font_xl: '3.4vw',
    font_xxl: '4.3vw',
    media_phone: 'max-width: 900px',
}