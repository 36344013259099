import React, {Component} from 'react';
import MetaTags from 'react-meta-tags';

class MetaTag extends Component {
  render() {
    return (
        <div className="wrapper">
          <MetaTags>
            <title>luksite - profesionalne strony internetowe</title>
            <meta property="og:title" content="luksite - profesionalne strony internetowe" />
            <meta name="description" content="Specjalizujemy się w tworzeniu oraz wdrażaniu nowoczesnych stron internetowych dla firm i osób prywatnych. Oferujemy kompleksową pomoc z zakresu technologicznego na każdym etapie współpracy." />
            <meta property="og:title" content="luksite.pl" />
          </MetaTags>
        </div>
      )
  }
}

export default MetaTag;