import React from 'react';
import styled from 'styled-components';
import Paragraph from '../atoms/Paragraph';
import Header from '../atoms/Header';
import iconPhone from '../../assets/phone.svg';
import iconMail from '../../assets/mail.svg';
import iconIg from '../../assets/instagram.svg';
import iconFb from '../../assets/facebook.svg';
import markIcon from '../../assets/mark.svg';
import Button from '../atoms/Button';
import SocialIcon from '../atoms/SocialIcon';
import FooterNav from '../molecules/FooterNav';
import logo from '../../assets/logo_2.svg';
import { links } from '../../links';
import { routes } from '../../routes';
import { NavLink } from 'react-router-dom';

const Wrapper = styled.div`
    padding: 10vw 0 5vw;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    &:before {
        content: 'kontakt';
        position: absolute;
        top: 0;
        left: -90px;
        text-transform: uppercase;
        font-size: 11vw;
        letter-spacing: 1vh;
        font-weight: 900;
        color: rgba(255,255,255,0.05);
    }
`;
const Contact = styled.div`
    padding: 0;
    width: 40%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    &::before {
        content: '';
        position: absolute;
        bottom: 0;
        right: 0;
        background: url(${markIcon});
        background-size: 100% 100%;
        background-repeat: no-repeat;
        width: 4vw;
        height: 4vw;
    }
    @media (${({theme}) => theme.media_phone}) {
        width: 100%;
    }
`;
const ContactLink = styled.a`
    text-decoration: none;
    position: relative;
    padding-left: 3vw;
    font-size: ${({theme}) => theme.font_m};
    width: 100%;
    cursor: pointer;
    color: white;
    &::before {
        content: '';
        position: absolute;
        top: -0.2vw;
        left: 0;
        background: url(${({icon}) => icon});
        width: ${({theme}) => theme.font_l};
        height: ${({theme}) => theme.font_l};
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: 50% 50%;
    }
    &:hover {
        text-decoration: underline;
    }
    @media (${({theme}) => theme.media_phone}) {
        font-size: 18px;
        padding-left: 40px;
        &:before {
            top: 2px;
            width: 22px;
            height: 22px;
        }
    }
`;
const StyledParagraph = styled(Paragraph)`
    margin: 15px 0 5px;
`;
const StyledButton = styled(Button)`
    margin: 50px 0 0;
    @media (${({theme}) => theme.media_phone}) {
        margin: 50px auto 0;
    }
`;
const MainHeader = styled(Header)`
    width: 100%;
`;
const StyledHeader = styled(Header)`
    font-size: ${({theme}) => theme.font_l};
    margin: 0px;
    text-transform: none;
`;
const InnerWrapper = styled.div`
    padding: 0 50px;
    @media (${({theme}) => theme.media_phone}) {
        padding: 20px 0;
    }
`;
const SocialMedia = styled.div`
    display: flex;
`;
const Logo = styled.img`
    position: absolute;
    width: 150px;
    bottom: 5vw;
    right: 0;
    z-index: 999;
`;

const Footer = () => (
    <Wrapper>
        <MainHeader>Kontakt z nami</MainHeader>
        <Contact>
                <Paragraph>Zadzwoń lub napisz do nas! Chętnie odpowiemy na wszystkie pytania i rozwiejemy wszelkie wątpliwości.</Paragraph>
                <StyledParagraph>Adres e-mail: </StyledParagraph>
                <ContactLink icon={iconPhone} href={links.mail}>kontakt@luksite.pl</ContactLink>
                <StyledParagraph primary>Numer telefonu: </StyledParagraph>
                <ContactLink icon={iconMail} href={links.phone_number}>515 484 453</ContactLink>
                <StyledButton as={NavLink} to={routes.contact}>formularz kontaktowy</StyledButton>
        </Contact>
        <InnerWrapper>
                <StyledHeader>Na skróty</StyledHeader>
                <FooterNav></FooterNav>
            <StyledHeader>Social media</StyledHeader>
            <SocialMedia>
                <SocialIcon icon={iconFb} href={links.facebook}></SocialIcon>
                <SocialIcon icon={iconIg} href={links.instagram}></SocialIcon>
            </SocialMedia>
        </InnerWrapper>
        <Logo src={logo}></Logo>
    </Wrapper>
);

export default Footer;
