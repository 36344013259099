import React from 'react';
import styled from 'styled-components';
import Header from '../atoms/Header';
import Paragraph from '../atoms/Paragraph';
import markIcon from '../../assets/mark.svg'
import fbIcons from '../../assets/facebook.svg';
import igIcons from '../../assets/instagram.svg';
import SocialIcon from '../atoms/SocialIcon';
import { links } from '../../links';

const Wrapper = styled.div`
    width: 100%;
    min-height: 85vh;
    margin: 0 auto;
    display: flex;
    align-items: center;
    position: relative;
    @media (${({theme}) => theme.media_phone}) {
        min-height: calc(100vh - 80px);
    }
`;

const ContentWrapper = styled.div`
    width: 90%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (${({theme}) => theme.media_phone}) {
        width: 100%;
        text-align: center;
    }
`;

const StyledParagraph = styled(Paragraph)`
    margin: 20px 0 50px;
    font-size: ${({theme}) => theme.font_l};
    line-height: 2.5rem;
    @media (${({theme}) => theme.media_phone}) {
        text-align: center;
    }
`;
const Image = styled.div`
    position: absolute;
    width: 10vw;
    height: 12vw;
    content: '';
    background: url(${markIcon});
    background-size: 100%;
    background-repeat: no-repeat;
    opacity: 0.5;
    bottom: 0;
    right: calc(50% - 5vw);
    @media (${({theme}) => theme.media_phone}) {
        position: absolute;
        bottom: 0;
        right: 20px;
        width: 80px;
        height: 100px;
    }
`;
const SocialMedia = styled.div`
    position: absolute;
    right: 0;
    bottom: 0;
    @media (${({theme}) => theme.media_phone}) {
        display: none;
    }
`;
const StyledHeader = styled(Header)`
    text-align: center;

`;

const MainHeader = ({information}) => (
    <Wrapper>
        <ContentWrapper>
            <StyledHeader big>{information.heading}</StyledHeader>
            <StyledParagraph center>{information.description}</StyledParagraph>
        </ContentWrapper>
        <Image/>
        <SocialMedia>
            <SocialIcon href={links.facebook} icon={fbIcons}/>
            <SocialIcon icon={igIcons} href={links.instagram}/>
        </SocialMedia>
    </Wrapper>
);

export default MainHeader;
