import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import logo from '../../assets/logo_2.svg';
import SocialIcon from '../atoms/SocialIcon';
import fbIcons from '../../assets/facebook.svg';
import igIcons from '../../assets/instagram.svg';
import { routes } from '../../routes';
import { links } from '../../links';

const Wrapper = styled.nav`
    position: fixed;
    top: 0;
    left: 0;
    background: black;
    z-index: 999;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    transition-duration: 0.5s;
    transform: translate(${({isVisible}) => (isVisible ? '0' : '100%')});
`;
const Logo = styled.img`
    display: block;
    width: 220px;
    height: 80px;
    margin: 0;
`;
const NavigationLink = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
`;
const StyledLink = styled.a`
    text-decoration: none;
    color: ${({theme}) => theme.grey_1};
    cursor: pointer;
    font-size: ${({theme}) => theme.font_l};
    transition-duration: 0.5s;
    margin-bottom: 10px;
    &:hover {
        color: ${({theme}) => theme.secondary_2};
    }
    @media (${({theme}) => theme.media_phone}) {
        font-size: 26px;
    }
`;
const SocialMedia = styled.div`
    display: flex;
    margin-top: 50px;
`;

const Navs = ({isVisible, handleClose}) => (
    <Wrapper isVisible={isVisible}>
        <NavLink to={routes.home}>
            <Logo onClick={handleClose} src={logo}/>
        </NavLink>
        <NavigationLink>
            <StyledLink onClick={handleClose} exact as={NavLink} to={routes.home}>O nas</StyledLink>
            <StyledLink onClick={handleClose} as={NavLink} to={routes.services} >Zakres usług</StyledLink>
            <StyledLink onClick={handleClose} as={NavLink} to={routes.realization}>Realizacje</StyledLink>
            <StyledLink onClick={handleClose}>Klienci</StyledLink>
            <StyledLink onClick={handleClose} as={NavLink} to={routes.contact}>Kontakt</StyledLink>
        </NavigationLink>
        <SocialMedia>
            <SocialIcon href={links.facebook} icon={fbIcons}/>
            <SocialIcon icon={igIcons} href={links.instagram}/>
        </SocialMedia>
    </Wrapper>
);

export default Navs;