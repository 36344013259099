import React from 'react';
import Slider from '../components/organisms/Slider';
import AboutUs from '../components/organisms/AboutUs';
import Populary from '../components/organisms/Populary';
import Steps from '../components/organisms/Steps';
import mediaIcon from '../assets/social-media.svg';
import pageIcon from '../assets/profile.svg';
import supportIcon from '../assets/settings.svg';
import mackup_5 from '../assets/realizations/zakrzew.png';
import mackup_2 from '../assets/realizations/slawek.jpg';
import mackup_3 from '../assets/realizations/drukdlaciebie.jpg';
import Realization from '../components/organisms/Realization';

const offers = [
    {
        name: 'social media',
        description: 'Zajmujemy się tworzeniem i prowadzeniem wizerunków firmowych w social-mediach. Tworzymy strategie i kampanie reklamowe.',
        cardIcon: mediaIcon,
        active: 0
    },
    {
        name: 'strony internetowe',
        description: 'Tworzymy profesjonalne i przejrzyste strony internetowe. Każdy projekt jest indywidualny i dostosowany do wymagań klienta.',
        cardIcon: pageIcon,
        active: 1
    },
    {
        name: 'obsługa techniczna',
        description: 'Dbamy o witryny internetowe od strony technicznej, wykonujemy prace związane z aktualizacją  i utrzymaniem stron internetowych.',
        cardIcon: supportIcon,
        acrive: 0
    },
];
const realizations = [
    {
        name: "Salon meblowy sławek",
        link: 'https://xn--sawek-k7a.pl/',
        description: 'Salon meblowy „Sławek” w Mszanie Dolnej to miejsce gdzie znajdziesz wszystko, aby umeblować swój dom czy mieszkanie.',
        cardIcon: mackup_2,
    },
    {
        name: "Druk dla Ciebie",
        link: 'https://drukdlaciebie.pl/',
        description: 'DARKSTORY to precyzja, jakość oraz kompleksowość w znakowaniu wszelkiego rodzaju produktów.',
        cardIcon: mackup_3,
    },
    {
        name: "Zagroda w zakrzewie",
        link: 'https://zagrodawzakrzewie.pl/',
        description: 'Zagroda w Zakrzewie to miejsce stworzone z miłości do zwierząt. Oferuje niezapomniane przygody na łonie natury.',
        cardIcon: mackup_5,
    },
];

const HomePage = () => (
    <>
        <Slider />
        <AboutUs />
        <Populary items={offers} header={true} />
        <Steps />
        <Realization items={realizations} header={true}/>
    </>
);

export default HomePage;