import styled from 'styled-components';

const SocialIcon = styled.a`
    text-decoration: none;
    display: block;
    margin: 5px;
    width: 50px;
    height: 50px;
    background: url(${({icon}) => icon});
    background-repeat: no-repeat;
    background-size: auto 60%;
    background-repeat: no-repeat;
    background-position: center;
    border: 1px solid white;
    border-radius: 50px;
    transition-duration: 0.5s;
    &:hover {
        background-color: ${({theme}) => theme.secondary_2};
        border-color: ${({theme}) => theme.secondary_2};
    }
`;

export default SocialIcon;