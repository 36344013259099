import React from 'react';
import MainHeader from '../components/organisms/MainHeader';
import Realization from '../components/organisms/Realization';
import mackup_1 from '../assets/realizations/niko.png';
import mackup_3 from '../assets/realizations/czystydom.png';
import mackup_4 from '../assets/realizations/kuczewski.png';
import mackup_5 from '../assets/realizations/pichers.jpg';
import mackup_6 from '../assets/realizations/kominkiiszafy.png';
import mackup_7 from '../assets/realizations/event.png';
import mackup_8 from '../assets/realizations/fotofamily.png';
import mackup_9 from '../assets/realizations/zbostrans.png';
import mackup_10 from '../assets/realizations/zakrzew.png';
import mackup_11 from '../assets/realizations/slawek.jpg';
import mackup_12 from '../assets/realizations/drukdlaciebie.jpg'


const headerValue = {
    heading: 'Zobacz jakie projekty mamy za sobą',
    description: 'Każdy projekt jest indywidualny i wykonywany zgodnie z wymaganiami naszego klienta. Publikacja następuje dopiero w momencie pełnej akceptacji i zadowolenia. Zapoznaj się z wybranymi realizacjami.'
}
const realizations = [
    {
        name: "Salon meblowy sławek",
        link: 'https://xn--sawek-k7a.pl/',
        description: 'Salon meblowy „Sławek” w Mszanie Dolnej to miejsce gdzie znajdziesz wszystko, aby umeblować swój dom czy mieszkanie.',
        cardIcon: mackup_11,
    },
    {
        name: "Druk dla Ciebie",
        link: 'https://drukdlaciebie.pl/',
        description: 'DARKSTORY to precyzja, jakość oraz kompleksowość w znakowaniu wszelkiego rodzaju produktów',
        cardIcon: mackup_12,
    },
    {
        name: "Zagroda w zakrzewie",
        link: 'https://zagrodawzakrzewie.pl/',
        description: 'Zagroda w Zakrzewie to miejsce stworzone z miłości do zwierząt. Oferuje niezapomniane przygody na łonie natury.',
        cardIcon: mackup_10,
    },
    {
        name: "Zbostrans",
        link: 'https://zbostrans.pl/',
        description: 'Zbostrans - Firma została w 1993 roku i od początku swojej działalności zajmuje się transportem i sprzedażą najlepszej jakości kruszyw i materiałów sypkich.',
        cardIcon: mackup_9,
    },
    {
        name: "Fotofamily",
        link: 'http://fotograflimanowa.pl/',
        description: 'Fotofamily - strona internetowa dla pasjonatki fotografii oferującej profesjonalne sesje portretowe, rodzinne, ślubne i plenerowe.',
        cardIcon: mackup_8,
    },
    {
        name: "Event-solution",
        link: 'https://imprezy-krakow.pl/',
        description: 'Event Solution - firma działająca na terenie Krakowa. W ofercie firmy znajduję się organizacja imprez firmowych oraz wszelkiego rodzaju przyjęć okolicznościowych.',
        cardIcon: mackup_7,
    },
    {
        name: "Intebo. - strona realizacji",
        link: 'https://kominkiiszafy.pl/',
        description: 'Intebo. Salon kominków i pieców - firma specjalizująca się w sprzedaży oraz montażu kominków na drewno, pieców na pellet, odkurzaczy centralnych oraz mechanicznych wentylacji.',
        cardIcon: mackup_6,
    },
    {
        name: "Pichers",
        link: 'https://pichers.pl/',
        description: 'Pichers - firma z kilkuletnim doświadczeniem zajmująca się sprzedażą internetową. Dołączając do zespołu otrzymujesz możliwość szybkiego zarobku jako pośrednik sprzedający ich produkty.',
        cardIcon: mackup_5,
    },
    {
        name: "Kuczewski - remonty",
        link: 'https://kuczewski-remonty.pl/',
        description: 'Kuczewski - profesjonalne remonty to firma specjalizująca się w wykonywaniu kompleksowych remontów mieszkań i domów jak również budowaniu nowoczesnych pawilonów użytkowych.',
        cardIcon: mackup_4,
    },
    {
        name: 'Wagner&Koss',
        link: 'https://czystydomkrakow.pl/',
        description: 'Firma Wagner&Koss zajmuje się kompleksowym sprzątaniem domów, mieszkań, biur, klatek schodowych, powierzchni wspólnych oraz innych obiektów użytkowych.',
        cardIcon: mackup_3,
    },
    {
        name: 'NIKO sp. z o.o.',
        link: 'http://nikospolka.pl/',
        description: 'Firma NIKO została założona w 2015 roku. W głównej mierze zajmuje się dystrybucją produktów FMCG (Fast Moving Consumer Goods), a w szczególności napojów bezalkoholowych.',
        cardIcon: mackup_1,
    },
];
const ContactPage = () => (
    <>
    <MainHeader information={headerValue}/>
    <Realization items={realizations} header={false}/>
    </>
);

export default ContactPage;