import React from 'react';
import PropTypes from 'prop-types';
import styled, {css} from 'styled-components';
import Header from '../atoms/Header';
import Paragraph from '../atoms/Paragraph';

const Card = styled.div`
    padding: 5vw 2vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 3px solid rgba(255, 255, 255, 0.1);
    transition-duration: 0.5s;
    &:hover {
        border-color: ${({theme}) => theme.secondary_2};
        transform: translateY(-5px);
        background: rgba(195, 23, 81,0.1);
    }
    @media (${({theme}) => theme.media_phone}) {
        padding: 5vw;
        &:last-of-type {
            margin-bottom: 50px;
        }
    }
    ${({active}) =>
    active && css`
       transform: translateY(-50px);
       &:hover {
        transform: translateY(-55px);
       }
       @media (${({theme}) => theme.media_phone}) {
           transform: translateY(0);
           &:hover {
            transform: translateY(-5px);
        }
       }
    `
    }
`;
const Icon = styled.img`
    height: 100px;
    margin-bottom: 50px;
`;
const StyledHeader = styled(Header)`
    font-size: ${({theme}) => theme.font_l};
    text-transform: none;
`;
const CardOffer = ({name, description, cardIcon, active}) => (
    <Card active={active}>
        <Icon src={cardIcon}/>
        <StyledHeader>{name}</StyledHeader>
        <Paragraph center mediacenter>{description}</Paragraph>
        {/* <StyledButton invers>Czytaj więcej</StyledButton> */}
    </Card>
);

CardOffer.propTypes = {
    items: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        description: PropTypes.string,
    }))
};
CardOffer.defaultProps = {
    items: [],
}
export default CardOffer;