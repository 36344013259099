import styled, {css} from 'styled-components';

const Button = styled.button`
    position: relative;
    text-decoration: none;
    border: 1px;
    border-style: solid;
    border-color: ${({theme}) => theme.secondary_2};
    padding: 15px 40px;
    border-radius: 50px;
    background: ${({theme}) => theme.secondary_2};
    color: white;
    transition: all .2s ease;
    font-size: ${({theme}) => theme.secondary_2};
    text-transform: uppercase;
    z-index: 9;
    &:hover {
        transform: translateY(-2px);
        background: none;
        color: ${({theme}) => theme.secondary_2};
        transform: translateY(-3px);
    }
    @media (${({theme}) => theme.media_phone}) {
        font-size: 16px;
    }
    ${({invers}) =>
    invers && css`
        color: ${({theme}) => theme.secondary_2};
        background: none;
        &:hover {
            background: ${({theme}) => theme.secondary_2};
            color: white;
        }
    `};
`;

export default Button;
