import React from 'react';
import styled from 'styled-components';
import arrowIcon from '../../assets/arrow.svg';
import { NavLink } from 'react-router-dom';
import { routes } from '../../routes';

const List = styled.div`
    margin-bottom: 50px;
    display: flex;
    flex-direction: column;
`;
const Link = styled.a`
    list-style: none;
    font-size: ${({theme}) => theme.font_m};
    position: relative;
    padding: 5px 0 5px 2vw;
    cursor: pointer;
    transition-duration: 0.4s;
    color: white;
    text-decoration: none;
    &::before {
        content: '';
        width: ${({theme}) => theme.font_l};
        height: ${({theme}) => theme.font_l};
        background: url(${arrowIcon});
        background-size: 100% auto;
        background-repeat: no-repeat;
        position: absolute;
        left: 0;
    }
    @media (${({theme}) => theme.media_phone}) {
        font-size: 16px;
        padding-left: 30px;
        &:before {
            top: 5px;
            width: 18px;
            height: 18px;
        }
    }
    &:hover {
        transform: translateX(20px);
    }
`;

const FooterNav= () => (
    <List>
        <Link as={NavLink} to={routes.home}>O nas</Link>
        <Link as={NavLink} to={routes.services}>Oferta</Link>
        <Link as={NavLink} to={routes.realization}>Realizacje</Link>
        <Link as={NavLink} to={routes.home}>Klienci</Link>
        <Link as={NavLink} to={routes.contact}>Kontakt</Link>
    </List>
);

export default FooterNav;