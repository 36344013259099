import React, {Component} from 'react';
import styled from 'styled-components';
import Header from '../atoms/Header';
import Paragraph from '../atoms/Paragraph';
import iconPhone from '../../assets/phone.svg';
import iconMail from '../../assets/mail.svg';
import bacIcon from '../../assets/bac.svg';
import Form from '../molecules/Form';
import { links } from '../../links';

const Wrapper = styled.div`
    display: flex;
    margin: 10vw 0;
    justify-content: space-between;
    align-items: center;
    background: url(${bacIcon});
    @media (${({theme}) => theme.media_phone}) {
        flex-direction: column;
    }
`;
const Description = styled.div`
    width: 40%;
    @media (${({theme}) => theme.media_phone}) {
        width: 100%;
        text-align: center;
        margin-bottom: 30px;
    }
`;
const StyledParagraph = styled(Paragraph)`
    margin-top: 20px;
`;
const ContactLink = styled.a`
    text-decoration: none;
    position: relative;
    text-align: left;
    font-size: ${({theme}) => theme.font_m};
    cursor: pointer;
    color: white;
    &:hover {
        text-decoration: underline;
    }
    @media (${({theme}) => theme.media_phone}) {
        font-size: 16px;
        text-align: left;
    }
`
const ContactWrapper = styled.div`
    padding-left: 70px;
    position: relative;
    text-align: left;
    &:before {
        content: '';
        position: absolute;
        left: 0;
        top: calc(50% - 25px);
        background: url(${({icon}) => icon});
        background-size: 80%;
        background-repeat: no-repeat;
        background-position: 50%;
        width: 50px;
        height: 50px;
    }
`;
class Contact extends Component {
    render() {
        return(
            <Wrapper>
                <Description>
                    <Header>Formularz kontaktowy</Header>
                    <Paragraph mediacenter>Masz jakieś pytanie? Chcesz skorzystać z naszje oferty? Wyślij wiadomość lub zostaw kontakt do siebie. Na pewno się odezwiemy!</Paragraph>
                    <ContactWrapper icon={iconPhone}>
                        <StyledParagraph>Adres e-mail: </StyledParagraph>
                        <ContactLink href={links.mail}>kontakt@luksite.pl</ContactLink>
                    </ContactWrapper>
                    <ContactWrapper icon={iconMail}>
                        <StyledParagraph primary>Numer telefonu: </StyledParagraph>
                        <ContactLink href={links.phone_number}>515 484 453</ContactLink>
                    </ContactWrapper>
                </Description>
                <Form/>
            </Wrapper>
        )
    }
}


export default Contact;