import React, {Component} from 'react';
import styled, { ThemeProvider} from 'styled-components';
import GlobalStyle from '../theme/GlobalStyle';
import { theme } from '../theme/mainTheme';
import Navs from '../components/molecules/Navs';
import Footer from '../components/organisms/Footer';
import NavBar from '../components/organisms/NavBar';
import MetaTag from '../function/MetaTag';
import Messenger from '../components/molecules/Messenger';

const Main = styled.div`
    padding: 0 5vw;
`;
class HomeTemplate extends Component {
  state = {
    isVisible: false,
  }
  handleVisibleBar = () => {
    this.setState(prevState => ({
      isVisible: !prevState.isVisible,
    }))
  }
  render() {
    const {children} = this.props;
    return (
        <>
          <ThemeProvider theme={theme}>
            <MetaTag/>
              <Main>
                <Navs isVisible={this.state.isVisible} handleClose={this.handleVisibleBar}/>
                <NavBar handleVisible={this.handleVisibleBar} close={this.state.isVisible}></NavBar>
                  {children}
                  <Footer/>
                  <Messenger/>
              </Main>
          </ThemeProvider>
          <GlobalStyle />
        </>
    )
  }

};

export default HomeTemplate;
