import React from 'react';
import styled from 'styled-components';
import Menu from '../atoms/Menu';
import logo from '../../assets/logo_2.svg';
import { NavLink } from 'react-router-dom';
import { routes } from '../../routes';

const Wrapper = styled.div`

`;
const Logo = styled.img`
    margin-top: 50px;
    min-width: 100px;
    width: 7vw;
    @media (${({theme}) => theme.media_phone}) {
        margin-top: 20px;
    }
`;

const NavBar = ({handleVisible, close}) => (
    <Wrapper>
        <NavLink to={routes.home}>
        <Logo src={logo}/>
        </NavLink>
        <Menu onClick={handleVisible} close={close}/>
    </Wrapper>
);

export default NavBar;