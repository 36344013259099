import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import Header from '../atoms/Header';
import Button from '../atoms/Button';
import markIcon from '../../assets/mark.svg';
import CardOffer from '../molecules/CardOffer';
import { routes } from '../../routes';


const Wrapper = styled.div`
    width: 100%;
    position: relative;
    text-align: center;
`;
const InnerWrapper = styled.div`
    margin: 8vw 0 4vw;
    display: grid;
    grid-template-columns: repeat(3,1fr);
    grid-gap: 20px;
    @media (${({theme}) => theme.media_phone}) {
        grid-template-columns: 1fr;
    }
`;

const MainHeader = styled(Header)`
    position: relative;
    &:after {
        content: '';
        position: absolute;
        width: 3.6vw;
        height: 3.6vw;
        background: url(${markIcon});
        background-repeat: no-repeat;
        background-size: 100% 100%;
        opacity: 0.4;
        bottom: -3.7vw;
    }
`;

const Populary = ({items, header}) => (
    <Wrapper>
        {header && <MainHeader>Najpopularniejsze usługi</MainHeader>}
        <InnerWrapper>
            {
                items.map(({name, description, cardIcon, active}) => (
                    <CardOffer name={name} description={description} cardIcon={cardIcon} active={active}></CardOffer>
                ))
            }
        </InnerWrapper>
        {header && <Button as={NavLink} to={routes.services}>Zobacz wszystkie usługi</Button>}
    </Wrapper>
);

export default Populary;